export const API_BASE_URL = SERVICE_URL;
export const API_RECORDS = `${API_BASE_URL}/api/records`;
export const API_COMPANIES = `${API_BASE_URL}/api/companies`;

export const DEFAULT_YEAR = 2020;
export const DEFAULT_JOB = 'PROFESSIONALS';
export const DEFAULT_INDUSTRY = 51;

// Mapping of EEO short values to numeric values for sorting.
export const EEOSortOrder = {
  HISP: 1,
  WH: 2,
  BLK: 3,
  NHOPI: 4,
  ASIAN: 5,
  AIAN: 6,
  TOMR: 7,
  MINORITY: 8,
  MALE: 9,
  FEMALE: 10,
};

export const YEARS = [2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021];

export const JOB_CATEGORIES = [
  'ALL',
  'SRMANAGERS',
  'MIDMANAGERS',
  'PROFESSIONALS',
  'TECHNICIANS',
  'SALESWORKERS',
  'CLERICALS',
  'CRAFTWORKERS',
  'OPERATIVES',
  'LABORERS',
  'SERVICEWORKERS',
];

export const NAICS_CODES = [
  11,
  21,
  21,
  22,
  23,
  31,
  32,
  33,
  42,
  44,
  45,
  48,
  49,
  51,
  52,
  53,
  54,
  55,
  56,
  61,
  62,
  71,
  72,
  81,
  92,
];
