// Mapping of EEO short values to human-readable strings.
const EEOValueMap = {
  HISP: 'Hispanic',
  WH: 'White',
  BLK: 'Black or African American',
  NHOPI: 'Native Hawaiian and Other Pacific Islander',
  ASIAN: 'Asian',
  AIAN: 'American Indian or Alaska Native',
  TOMR: 'Two or More Races',
  MINORITY: 'Minority',
  MALE: 'Male',
  FEMALE: 'Female',
};

// Status and warning messages.
const STATUS_MESSAGES = {
  STATUS_LOADING: 'Loading...',
  STATUS_NO_DATA: 'No data for the current selection.',
  STATUS_NO_MEMBERS: 'No workforce members match the current selections.',
  WARNING_NO_DATA: 'WARNING: No data available based on the current selections.',
  WARNING_INDUSTRY_MISMATCH: 'WARNING: This industry does not match the reference data (1st column).',
};

// Misc strings.
const TOTAL_PEOPLE_MESSAGE = 'Total people:';

export { EEOValueMap, STATUS_MESSAGES, TOTAL_PEOPLE_MESSAGE };
